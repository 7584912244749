var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',{staticStyle:{"align-items":"center"}},[_c('ApplicationEditDialog',{on:{"save":function($event){return _vm.getApplicationList()}}},[_c('v-btn',{staticClass:"mr-2",attrs:{"color":"info","depressed":""}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("App ")],1)],1),_c('v-spacer'),_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search apps","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"label":"Status","items":_vm.filterStatusItems,"item-text":"text","item-value":"value"},model:{value:(_vm.filterStatus),callback:function ($$v) {_vm.filterStatus=$$v},expression:"filterStatus"}})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.getApplicationsByFilters(),"expanded":_vm.expanded,"search":_vm.search,"sort-by":['id'],"sort-desc":[true],"item-class":_vm.rowClasses,"loading":_vm.loading,"hide-default-footer":"","disable-pagination":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"app-name"},[_c('div',{staticClass:"app-name__line1"},[_vm._v(_vm._s(item.name))]),_c('div',{staticClass:"app-name__line2"},[_vm._v(" "+_vm._s(_vm.getVendorName(item.vendor))+" "),(item.vendorList.length > 1)?_c('v-chip',{staticStyle:{"margin-top":"-2px"},attrs:{"x-small":""}},[_vm._v(_vm._s(item.vendorList.length))]):_vm._e()],1)])]}},{key:"item.vendor.proxy_status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pl-2"},[_c('ApplicationProxyStatus',{attrs:{"status":item.vendor.proxy_status || null}})],1)]}},{key:"item.vendor.last_sync_summary_sales_job",fn:function(ref){
var item = ref.item;
return [_c('ApplicationReportStatus',{attrs:{"application":{},"latestReport":item.vendor.last_sync_summary_sales_job,"enabled":true}})]}},{key:"item.vendor.last_sync_subscription_event_job",fn:function(ref){
                var item = ref.item;
return [_c('ApplicationReportStatus',{attrs:{"application":{},"latestReport":item.vendor.last_sync_subscription_event_job,"enabled":true}})]}},{key:"item.vendor.last_sync_subscriber_job",fn:function(ref){
                var item = ref.item;
return [_c('ApplicationReportStatus',{attrs:{"application":{},"latestReport":item.vendor.last_sync_subscriber_job,"enabled":true}})]}},{key:"item.last_backend_events_sync_job",fn:function(ref){
                var item = ref.item;
return [_c('ApplicationReportStatus',{attrs:{"application":item,"latestReport":item.last_backend_events_sync_job,"enabled":item.enabled && item.parsing_events_from_app}})]}},{key:"item.last_backend_reviews_sync_job",fn:function(ref){
                var item = ref.item;
return [_c('ApplicationReportStatus',{attrs:{"application":item,"latestReport":item.last_backend_reviews_sync_job,"enabled":item.enabled && item.is_collect_app_reviews}})]}},{key:"item.last_backend_refunds_sync_job",fn:function(ref){
                var item = ref.item;
return [_c('ApplicationReportStatus',{attrs:{"application":item,"latestReport":item.last_backend_refunds_sync_job,"enabled":item.enabled && item.is_refund_parsing}})]}},{key:"item.last_backend_unsubs_sync_job",fn:function(ref){
                var item = ref.item;
return [_c('ApplicationReportStatus',{attrs:{"application":item,"latestReport":item.last_backend_unsubs_sync_job,"enabled":item.enabled && item.parse_backend_unsubscribes}})]}},{key:"item.last_backend_pp_sync_job",fn:function(ref){
                var item = ref.item;
return [_c('ApplicationReportStatus',{attrs:{"application":item,"latestReport":item.last_backend_pp_sync_job,"enabled":item.enabled && item.parse_backend_pp}})]}},{key:"item.last_reviews_sync_job",fn:function(ref){
                var item = ref.item;
return [_c('ApplicationReportStatus',{attrs:{"application":item,"latestReport":item.last_reviews_sync_job,"enabled":item.enabled && item.is_collect_app_reviews}})]}},{key:"item.last_mailbox_sync_job",fn:function(ref){
                var item = ref.item;
return [_c('ApplicationReportStatus',{attrs:{"application":item,"latestReport":item.last_mailbox_sync_job,"enabled":item.enabled && item.parse_mailbox}})]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('ApplicationEditDialog',{attrs:{"applicationId":item.id},on:{"save":function($event){return _vm.getApplicationList()}}},[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"info","fab":"","x-small":"","depressed":""}},[_c('v-icon',{attrs:{"aria-hidden":"false"}},[_vm._v(" mdi-pencil ")])],1)],1),_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"fab":"","x-small":"","depressed":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"aria-hidden":"false"}},[_vm._v(" mdi-dots-horizontal ")])],1)]}}],null,true)},[_c('v-list',[_c('v-hover',{attrs:{"open-delay":"0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-list-item',{class:{ 'menu-hover': hover },attrs:{"disabled":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-sync")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Sync AppStore Data")])],1)],1)]}}],null,true)}),_c('v-hover',{attrs:{"open-delay":"0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-list-item',{class:{ 'menu-hover': hover },attrs:{"href":_vm.getXLSDownloadLink(item),"disabled":false /*canGetXLSDownloadLink(item) === false*/}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-download")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Get Data XLS")])],1)],1)]}}],null,true)}),_c('ApplicationRemoteBackendEditDialog',{attrs:{"application":item}},[_c('v-hover',{attrs:{"open-delay":"0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-list-item',{class:{ 'menu-hover': hover },attrs:{"disabled":""}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-remote")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Backend Settings")])],1)],1)]}}],null,true)})],1),_c('v-hover',{attrs:{"open-delay":"0"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var hover = ref.hover;
return [_c('v-list-item',{class:{ 'menu-hover': hover },on:{"click":function($event){return _vm.remove(item)}}},[_c('v-list-item-icon',[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-trash-can")])],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"red--text"},[_vm._v("Remove Application")])],1)],1)]}}],null,true)})],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }