var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',[_c('v-card-title',[_vm._v(" Reviews Parsers "),_c('ManualReviewsParserControlDialog',{attrs:{"parser":null}}),_c('v-spacer'),_c('ManualReviewsProxyControlDialog',{attrs:{"settings":this.settings}})],1),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.parsers,"items-per-page":50},scopedSlots:_vm._u([{key:"item.app",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":_vm.getResultsUrl(item)}},[_vm._v(_vm._s(item.app))])]}},{key:"item.reviews",fn:function(ref){
var item = ref.item;
return [(item.reviews == 0)?_c('v-chip',{staticClass:"gray",attrs:{"to":_vm.getResultsUrl(item)}},[_vm._v(_vm._s(item.reviews))]):_c('v-chip',{staticClass:"info",attrs:{"to":_vm.getResultsUrl(item)}},[_vm._v(_vm._s(item.reviews))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('ManualReviewsParserStatus',{attrs:{"parser":item}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('ManualReviewsParserControlDialog',{attrs:{"parser":item}}),_c('AgreeDialog',{staticClass:"ml-2",on:{"remove":function($event){return _vm.remove(item)}}})]}}])})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }