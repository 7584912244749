var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-form',{ref:"form",staticClass:"mb-6",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.getData()}}},[_c('v-select',{attrs:{"item-text":"name","item-value":"id","items":_vm.applications,"label":"Application","rules":_vm.rules.applicationRules},on:{"change":function($event){return _vm.onApplicationChange()}},model:{value:(_vm.applicationSelected),callback:function ($$v) {_vm.applicationSelected=$$v},expression:"applicationSelected"}}),_c('v-select',{attrs:{"item-text":"name","item-value":"id","items":_vm.subscriptions,"label":"Subscription Name","multiple":"","chips":"","clearable":""},model:{value:(_vm.subscriptionSelected),callback:function ($$v) {_vm.subscriptionSelected=$$v},expression:"subscriptionSelected"}}),_c('v-select',{attrs:{"items":_vm.countries,"label":"Geo","multiple":"","chips":"","clearable":""},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}}),_c('v-btn',{staticClass:"mt-2",attrs:{"depressed":"","color":"primary","type":"submit"}},[_vm._v(" Get Data ")])],1)],1)],1),_c('v-data-table',{staticClass:"elevation-0 no-wrap",attrs:{"headers":_vm.dataHeaders,"sort-by":'leads',"sort-desc":true,"items":_vm.data,"items-per-page":50,"loading":_vm.isDataLoading === true,"loading-text":"Loading... Please wait"},scopedSlots:_vm._u([{key:"item.arpi",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDecimals(item.arpi, 2)))])]}},{key:"item.arpt",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDecimals(item.arpt, 2)))])]}},{key:"item.arps",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDecimals(item.arps, 2)))])]}},{key:"item.arpt_1m",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDecimals(item.arpt_1m, 2)))])]}},{key:"item.arps_1m",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDecimals(item.arps_1m, 2)))])]}},{key:"item.arpt_3m",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDecimals(item.arpt_3m, 2)))])]}},{key:"item.arps_3m",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDecimals(item.arps_3m, 2)))])]}},{key:"item.arpt_6m",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDecimals(item.arpt_6m, 2)))])]}},{key:"item.arps_6m",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDecimals(item.arps_6m, 2)))])]}},{key:"item.ins_t",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDecimals(item.ins_t, 1))+" %")])]}},{key:"item.t_s",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDecimals(item.t_s, 1))+" %")])]}},{key:"item.i_s",fn:function(ref){
var item = ref.item;
return [_c('div',[_vm._v(_vm._s(_vm.formatDecimals(item.i_s, 1))+" %")])]}}])},[_c('template',{slot:"body.prepend"},[(_vm.data.length)?_c('tr',[_c('th',{attrs:{"colspan":"3"}},[_vm._v("Total")]),_c('th',[_vm._v(_vm._s(_vm.total.proceeds))]),_c('th',[_vm._v(_vm._s(_vm.total.first_paid))]),_c('th',[_vm._v(_vm._s(_vm.total.rebills))]),_c('th',[_vm._v(_vm._s(_vm.formatDecimals(_vm.total.arpi, 2)))]),_c('th',[_vm._v(_vm._s(_vm.formatDecimals(_vm.total.arpt, 2)))]),_c('th',[_vm._v(_vm._s(_vm.formatDecimals(_vm.total.arps, 2)))]),_c('th',[_vm._v(_vm._s(_vm.total.proceeds_1m))]),_c('th',[_vm._v(_vm._s(_vm.formatDecimals(_vm.total.arpt_1m, 2)))]),_c('th',[_vm._v(_vm._s(_vm.formatDecimals(_vm.total.arps_1m, 2)))]),_c('th',[_vm._v(_vm._s(_vm.formatDecimals(_vm.total.arpt_3m, 2)))]),_c('th',[_vm._v(_vm._s(_vm.formatDecimals(_vm.total.arps_3m, 2)))]),_c('th',[_vm._v(_vm._s(_vm.formatDecimals(_vm.total.arpt_6m, 2)))]),_c('th',[_vm._v(_vm._s(_vm.formatDecimals(_vm.total.arps_6m, 2)))]),_c('th',[_vm._v(_vm._s(_vm.total.installs))]),_c('th',[_vm._v(_vm._s(_vm.total.leads))]),_c('th',[_vm._v(_vm._s(_vm.total.trials))]),_c('th',[_vm._v(_vm._s(_vm.total.subs))]),_c('th',[_vm._v(_vm._s(_vm.formatDecimals(_vm.total.ins_t, 1))+" %")]),_c('th',[_vm._v(_vm._s(_vm.formatDecimals(_vm.total.t_s, 1))+" %")]),_c('th',[_vm._v(_vm._s(_vm.formatDecimals(_vm.total.i_s, 1))+" %")])]):_vm._e()])],2)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }