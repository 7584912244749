<template>
<v-container fluid>
    <v-row>
        <v-col>

            <v-card class="pa-4">

                <v-row>
                    <v-col cols="6">
                        <v-form
                            class="mb-6"
                            ref="form"
                            lazy-validation
                            @submit.prevent="calculateRetention()"
                        >
                            <div class="mb-1" style="color: rgba(0, 0, 0, 0.6);">Select Subscriptions</div>

                            <v-card
                                class="mx-auto pa-0"
                                outlined
                                color="rgba(0, 0, 0, 0.2)"
                                style="max-height: 300px; overflow: auto;"
                            >
                                <v-list>
                                    <v-list-item-group
                                        v-model="subscriptionsSelected"
                                        multiple
                                    >
                                        <template v-for="(application, i) in subscriptions">
                                            <v-divider
                                                v-if="i !== 0"
                                                :key="`divider-${i}`"
                                            ></v-divider>

                                            <div class="mt-2 px-3" :key="i">{{ application.name }}</div>
                                            
                                            <template v-if="!application.subscriptions.length">
                                                <p class="px-5" :key="`no-items-${i}`">No subscriptions</p>
                                            </template>
                                            <template v-for="subscription in application.subscriptions">
                                                <v-list-item
                                                    :key="`item-${subscription.id}`"
                                                    :value="subscription.id"
                                                    active-class="primary--text text--accent-4"
                                                >
                                                    <template v-slot:default="{ active }">
                                                        <v-list-item-action>
                                                            <v-checkbox
                                                            :input-value="active"
                                                            color="primary"
                                                            ></v-checkbox>
                                                        </v-list-item-action>

                                                        <v-list-item-content>
                                                            <v-list-item-title v-text="subscription.name"></v-list-item-title>
                                                        </v-list-item-content>
                                                    </template>
                                                </v-list-item>
                                            </template>
                                        </template>
                                    </v-list-item-group>
                                </v-list>
                            </v-card>

                            <div class="my-2 mb-4">
                                <v-chip
                                    v-for="(subscriptionId, i) in subscriptionsSelected"
                                    :key="i"
                                    class="mr-2 mb-1"
                                    close
                                    @click:close="clearSelectedSubscriptionById(subscriptionId)"
                                >
                                    {{ getSubscriptionNameById(subscriptionId) }}
                                </v-chip>
                            </div>

                            <v-select
                                item-text="text"
                                item-value="value"
                                v-model="displayData"
                                :items="availableDisplayData"
                                class="display-data"
                                label="Select Display Data"
                                multiple
                                chips
                                clearable
                            ></v-select>

                            <v-select
                                v-model="sortByData"
                                :items="availableDisplayData"
                                label="Sort By"
                                clearable
                                @change="refreshSort()"
                            ></v-select>

                                <v-menu
                                    v-model="dateFromMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="dateFrom"
                                            label="Date From"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            clearable
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="dateFrom"
                                        @input="dateFromMenu = false"
                                    ></v-date-picker>
                                </v-menu>

                                <v-menu
                                    v-model="dateToMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="auto"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="dateTo"
                                            label="Date To"
                                            prepend-icon="mdi-calendar"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                            clearable
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="dateTo"
                                        @input="dateToMenu = false"
                                    ></v-date-picker>
                                </v-menu>

                            <v-btn
                                class="mt-2"
                                depressed
                                color="primary"
                                type="submit"
                                :loading="showTableDataLoading === true"
                            >
                                Calculate
                            </v-btn>

                            <p class="mt-4">
                                First payment date: <strong>{{ resultFirstSubscriptionDate || '--' }}</strong><br />
                                Subscriptions affected: <strong>{{ resultSubscriptionsAffected || '--' }}</strong><br />
                                Total trials: <strong>{{ resultTotalTrials || '--' }}</strong><br />
                                Total installs: <strong>{{ resultTotalInstalls || '--' }}</strong><br/>
                            </p>

                        </v-form>

                    </v-col>
                </v-row>

                <v-row v-if="tableHeaders.length || tableData.length">
                    <v-col>
                        <v-data-table
                            :headers="tableHeaders"
                            hide-default-header
                            fixed-header
                            :sort-by="sortParams.by"
                            :sort-desc="sortParams.descending"
                            :items="tableData"
                            class="elevation-0 no-wrap"
                            :loading="showTableDataLoading === true"
                            loading-text="Loading... Please wait"
                            hide-default-footer
                            disable-pagination
                        >
                            <template #header="{ }">
                                <thead class="v-data-table-header">
                                    <tr>
                                        <th 
                                            v-for="(header, i) in tableHeaders" 
                                            :key="i"
                                            v-bind:class="[getSortActiveClasses(header)]"
                                            @click="changeSort(header)"
                                        >
                                            <div :class="['cell-content', header.is_predicted ? 'total-predicted' : '']">
                                                <div>{{ header.text }} <v-icon class="v-data-table-header__icon" small>mdi-arrow-up</v-icon></div>
                                            </div>
                                        </th>
                                    </tr>
                                    <tr v-if="tableData.length" class="total">
                                        <th v-for="(header, i) in tableHeaders" :key="i" v-bind:class="['']">
                                            <div :class="['cell-content', header.is_predicted ? 'total-predicted' : '']">
                                                <div v-if="header.type === 'country'">{{ tableTotal[header.country_key] }}</div>
                                                <div v-if="header.type === 'labels'" v-html="getCellDataLabels()"></div>
                                                <div v-if="header.type === 'period_data'" v-html="getCellData(header, tableTotal)"></div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                            </template>

                            <template v-for="(header, i) in tableHeaders" v-slot:[`item.${header.value}`]="{ item }">
                                <div :key="i" v-bind:class="['cell-content', header.is_predicted ? 'data-predicted' : '']">
                                    <div v-if="header.type === 'country'">{{ item[header.country_key] }}</div>
                                    <div v-if="header.type === 'labels'" v-html="getCellDataLabels()"></div>
                                    <div v-if="header.type === 'period_data'" v-html="getCellData(header, item)"></div>
                                </div>
                            </template>

                        </v-data-table>
                    </v-col>
                </v-row>

            </v-card>

        </v-col>
    </v-row>
</v-container>
</template>

<script>

import {objectToQueryString} from "../../../utils/helpers";
import {converValidationServerErrorsToString} from "../../../utils/errorUtil";

export default {
    name: "AdminRetentionV4Page",
    components: {},
    data() {
        return {
            rules: {
            },
            subscriptionsSelected: [],
            subscriptions: [],
            tableHeaders: [],
            tableTotal: {},
            tableData: [],
            showTableDataLoading: false,
            sortParams: {
                by: 'period1_proceeds',
                descending: true,
            },
            availableDisplayData: [],
            displayData: [],
            sortByData: null,
            dateFrom: null,
            dateFromMenu: false,
            dateTo: null,
            dateToMenu: false,
            resultFirstSubscriptionDate: null,
            resultSubscriptionsAffected: null,
            resultTotalTrials: null,
            resultTotalInstalls: null,
        }
    },
    watch: {
        subscriptionsSelected: function (newValue, oldValue) {
            localStorage.setItem('retention_v2_v3_subscriptions_selected', JSON.stringify(newValue));
        },
        displayData: function (newValue, oldValue) {
            localStorage.setItem('retention_v4_table_display_data', JSON.stringify(newValue));
        },
        sortByData: function (newValue, oldValue) {
            localStorage.setItem('retention_v4_table_default_sort', JSON.stringify(newValue));
        },
        dateFrom: function (newValue, oldValue) {
            localStorage.setItem('retention_v4_table_default_date_from', JSON.stringify(newValue));
        },
        dateTo: function (newValue, oldValue) {
            localStorage.setItem('retention_v4_table_default_date_to', JSON.stringify(newValue));
        },
    },
    async mounted() {
        await this.getSubscriptions();
        await this.getRetentionSettings();

        this.restoreSelectedSubscriptions();
        this.restoreTableSettings();
    },
    methods: {
        async getSubscriptions() {
            try {
                const response = await this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/admin/subscriber/subscriptions`)
                this.subscriptions = response.data.subscriptions;
            } catch (err) {
                alert(converValidationServerErrorsToString(err));
            }
        },
        async getRetentionSettings() {
            try {
                const response = await this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/retention-v4/settings`)
                this.availableDisplayData = response.data.available_row_data;
            } catch (err) {
                alert(converValidationServerErrorsToString(err));
            }
        },
        calculateRetention() {
            if (!this.$refs.form.validate()) {
                return false;
            }

            if (!this.subscriptionsSelected.length) {
                return false;
            }

            const q = objectToQueryString({
                subscriptions: this.subscriptionsSelected,
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
            });

            this.showTableDataLoading = true;

            this.$http.get(`${process.env.VUE_APP_SERVER_BASE_URL}/retention-v4/calculate?${q}`).then(resp => {

                this.showTableDataLoading = false;
                const data = resp.data.data;

                if (!data.data.length) {
                    alert('No data');
                    this.tableHeaders = [];
                    this.tableData = [];
                    this.tableTotal = [];
                    this.resultFirstSubscriptionDate = null;
                    this.resultSubscriptionsAffected = null;
                    this.resultTotalTrials = null;
                    this.resultTotalInstalls = null;
                    return;
                }

                this.tableHeaders = data.headers;
                this.tableData = data.data;
                this.tableTotal = data.total;
                this.resultFirstSubscriptionDate = data.first_event_date;
                this.resultSubscriptionsAffected = data.subscribers_affected;
                this.resultTotalTrials = data.total_trials;
                this.resultTotalInstalls = data.total_installs;
            })
            .catch(err => {
                this.showTableDataLoading = false;
                alert(converValidationServerErrorsToString(err))
            });
        },
        getSortActiveClasses(header)
        {
            let headerValue = null;
            if (header.type === 'country') {
                headerValue = 'country';
            }
            if (header.type === 'period_data') {
                headerValue = header[`${this.sortByData}_key`];
            }

            return [
                'sortable',
                this.sortParams.by == headerValue ? 'active': '',
                (this.sortParams.by != headerValue ? 'desc' : (this.sortParams.descending ? 'desc':'asc'))
            ];
        },
        changeSort(header) {
            let headerValue = null;
            if (header.type === 'country') {
                headerValue = 'country';
            }
            if (header.type === 'period_data') {
                headerValue = header[`${this.sortByData}_key`];
            }
            if (this.sortParams.by === headerValue) {
                this.sortParams.descending = !this.sortParams.descending;
            } else {
                this.sortParams.by = headerValue;
                this.sortParams.descending = true;
            }
        },
        refreshSort() {
            if (this.sortParams.by === 'country') {
                return;
            }
            const sortParts = this.sortParams.by.split('_');
            const periodPrefix = `${sortParts[0]}_`;

            let findKey = null;

            for (let i in this.tableHeaders) {
                const header = this.tableHeaders[i];
                if (header.type === 'country') {
                    continue;
                }
                if (header.period_prefix === periodPrefix) {
                    findKey = header[`${this.sortByData}_key`];
                    break;
                }
            }

            if (findKey) {
                this.sortParams.by = findKey;
            }
        },
        getSubscriptionNameById(subscriptionId)
        {
            for (let i in this.subscriptions) {
                const applicationName = this.subscriptions[i].name;
                if (this.subscriptions[i].subscriptions.length) {
                    for (let j in this.subscriptions[i].subscriptions) {
                        if (this.subscriptions[i].subscriptions[j].id == subscriptionId) {
                            return `${applicationName}: ${this.subscriptions[i].subscriptions[j].name}`;
                        }
                    }
                }
            }
            return subscriptionId;
        },
        clearSelectedSubscriptionById(subscriptionId)
        {
            const index = this.subscriptionsSelected.indexOf(subscriptionId);
            if (index !== -1) {
                this.subscriptionsSelected.splice(index, 1);
            }
        },
        restoreSelectedSubscriptions()
        {
            const subscriptionIds = JSON.parse(localStorage.getItem('retention_v2_v3_subscriptions_selected'));
            this.subscriptionsSelected = subscriptionIds;
        },
        restoreTableSettings()
        {
            const selectedDisplayDataIds = JSON.parse(localStorage.getItem('retention_v4_table_display_data'));
            const selectedDefaultSortId = JSON.parse(localStorage.getItem('retention_v4_table_default_sort'));
            const defaultDateFrom = JSON.parse(localStorage.getItem('retention_v4_table_default_date_from'));
            const defaultDateTo = JSON.parse(localStorage.getItem('retention_v4_table_default_date_to'));

            this.displayData = selectedDisplayDataIds || [];
            this.sortByData = selectedDefaultSortId ?? null;
            this.dateFrom = defaultDateFrom ?? null;
            this.dateTo = defaultDateTo ?? null;

            if (!this.displayData.length) {
                this.displayData.push(this.availableDisplayData[0].value);
            }
            if (!this.sortByData) {
                this.sortByData = this.availableDisplayData[0].value;
            }

        },
        getCellData(header, data)
        {
            let result = [];
            for (let i in this.displayData) {
                let findAvailableDisplayData = null;
                for (let j in this.availableDisplayData) {
                    if (this.displayData[i] == this.availableDisplayData[j].value) {
                        findAvailableDisplayData = this.availableDisplayData[j];
                        break;
                    }
                }

                let value = data[header[`${this.displayData[i]}_key`]];

                if (findAvailableDisplayData && findAvailableDisplayData.append) {
                    value = `${value} ${findAvailableDisplayData.append}`;
                }

                result.push(value);
            }
            return result.join("<br />");
        },
        getCellDataLabels() {
            let result = [];
            for (let i in this.displayData) {
                const displayData = this.getDisplayDataByIndex(i);
                result.push(displayData.text);
            }
            return result.join("<br />");
        },
        getDisplayDataByIndex(index) {
            let result = {};
            const currentDisplayData = this.displayData[index];
            for (let j in this.availableDisplayData) {
                if (currentDisplayData == this.availableDisplayData[j].value) {
                    result = this.availableDisplayData[j];
                    break;
                }
            }
            return result;
        }
    }

}

</script>

<style>
    .no-wrap table th {
        /* white-space: nowrap !important; */
        min-width: 3%;
    }

    table tr.total th {
        color: #000 !important;
    }

    th, td {
        /* height: auto !important; */
        padding: 0 !important;
    }

    .cell-content {
        display: flex;
        flex-direction: row;
        justify-content: left;
        align-items: center;
        height: 100%;
        padding: 2px 8px;
    }

    .total-predicted {
        background-color: #DCEBF6;
    }

    .data-predicted {
        background-color: #E3F3FF;
    }

    tr:hover .data-predicted {
        background-color: #C3D1DB;
    }

    .display-data .v-select__selections .v-chip {
        width: 100%;
    }

    .display-data .v-select__selections input {
        display: none;
    }
</style>