var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card-title',{staticStyle:{"align-items":"center"}},[_c('VendorEditDialog',{on:{"save":function($event){return _vm.loadVendors()}}},[_c('v-btn',{attrs:{"color":"info","depressed":""}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v("Account ")],1)],1),_c('v-spacer'),_c('v-row',[_c('v-col',{attrs:{"cols":"9"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search accounts / vendors / apps","single-line":"","hide-details":"","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-select',{attrs:{"label":"Status","items":_vm.filterStatusItems,"item-text":"text","item-value":"value"},model:{value:(_vm.filterStatus),callback:function ($$v) {_vm.filterStatus=$$v},expression:"filterStatus"}})],1)],1)],1),_c('v-card-text',[_c('v-data-table',{attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.getVendorsData(),"sort-by":['id'],"sort-desc":[true],"item-class":null,"hide-default-footer":"","disable-pagination":"","loading":_vm.loading},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"vendor-name"},[_c('div',{staticClass:"vendor-name__line1 mt-1"},[_vm._v(_vm._s(_vm.getVendorName(item)))]),_c('div',{staticClass:"vendor-name__line2"},[_vm._v(" Vendor: #"+_vm._s(item.number)+" ")]),_c('div',{staticClass:"vendor-name__line3 mt-1 mb-2"},[(item.applicationList.length)?_vm._l((item.applicationList),function(app){return _c('span',{key:app.id,staticClass:"mr-1"},[_c('v-chip',{attrs:{"x-small":""}},[_vm._v(_vm._s(app.name))])],1)}):_c('v-chip',{attrs:{"x-small":"","color":"black","text-color":"white"}},[_vm._v("No apps")])],2)])]}},{key:"item.proxy_status",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pl-2"},[_c('ApplicationProxyStatus',{attrs:{"status":item.proxy_status || null}})],1)]}},{key:"item.last_sync_summary_sales_job",fn:function(ref){
var item = ref.item;
return [_c('ApplicationReportStatus',{attrs:{"application":{},"latestReport":item.last_sync_summary_sales_job,"enabled":!!item.applicationList.length}})]}},{key:"item.last_sync_subscription_event_job",fn:function(ref){
var item = ref.item;
return [_c('ApplicationReportStatus',{attrs:{"application":{},"latestReport":item.last_sync_subscription_event_job,"enabled":!!item.applicationList.length}})]}},{key:"item.last_sync_subscriber_job",fn:function(ref){
var item = ref.item;
return [_c('ApplicationReportStatus',{attrs:{"application":{},"latestReport":item.last_sync_subscriber_job,"enabled":!!item.applicationList.length}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('VendorEditDialog',{attrs:{"vendorId":item.id},on:{"save":function($event){return _vm.loadVendors()}}},[_c('v-btn',{staticClass:"info",attrs:{"fab":"","x-small":"","depressed":""}},[_c('v-icon',{attrs:{"aria-hidden":"false"}},[_vm._v(" mdi-pencil ")])],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }