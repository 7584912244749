import Vue from 'vue'
import App from './App.vue'
import Axios from 'axios'
import VueRouter from 'vue-router'
import vuetify from './plugins/vuetify';
import LoadScript from "vue-plugin-load-script";
import {VueMaskDirective} from 'v-mask'
import VueDadata from 'vue-dadata'
import {getters, store} from './store'
import {router} from './router/router'
import VueApexCharts from 'vue-apexcharts'
import VueEllipseProgress from 'vue-ellipse-progress';

Vue.use(VueApexCharts)
Vue.component('apexchart', VueApexCharts)
Vue.use(VueEllipseProgress);
Vue.directive('mask', VueMaskDirective)
Vue.use(VueDadata)
Vue.prototype.$http = Axios
Vue.config.productionTip = false
// Vue.config.devtools = true
Vue.use(require('vue-moment'))
Vue.use(VueRouter)
Vue.use(LoadScript);

Vue.mixin({
    methods: {
        can(permissionName) {
            if (!store.getters.GET_USER.currentPermissions) {
                return false;
            } else {
                return store.getters.GET_USER.currentPermissions.indexOf(permissionName) == -1 ? false : true;
            }

        },
    },
}
);

require('dotenv').config()

store.commit('initialiseStore')

/*
const firebaseConfig = {
    apiKey: process.env.VUE_APP_API_KEY,
    authDomain: process.env.VUE_APP_AUTH_DOMAIN,
    databaseURL: process.env.VUE_APP_DATABASE_URL,
    projectId: process.env.VUE_APP_PROJECT_ID,
    storageBucket: process.env.VUE_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.VUE_APP_MESSAGING_SENDER_ID,
    appId: process.env.VUE_APP_APP_ID,
    measurementId: process.env.VUE_APP_MEASUREMENT_ID
};

Promise.resolve()
    .then(() => Vue.loadScript("https://www.gstatic.com/firebasejs/7.14.0/firebase-app.js"))
    .then(() => Vue.loadScript("https://www.gstatic.com/firebasejs/7.14.0/firebase-messaging.js"))
    .then(() => Vue.loadScript("https://www.gstatic.com/firebasejs/7.14.0/firebase-analytics.js"))
    .then(() => {
        window.firebase.initializeApp(firebaseConfig);
        const messaging = window.firebase.messaging();

        messaging.requestPermission()
            .then(() => {
                console.log("Have permission")
                return messaging.getToken()
            }).then(token => console.log(`FCM-Token: ${token}`)) // todo save to storage
            .catch(err => console.log("Error Occured: ", err))

        messaging.onMessage(payload => {
            // todo need to service worker implementing for work
            console.log('onMessage: ', payload)
        })
    });*/

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
