var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('v-card',{staticClass:"pa-4"},[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('v-select',{attrs:{"item-text":"name","item-value":"id","items":_vm.applications,"label":"Application"},on:{"change":function($event){return _vm.getMailboxForApplication(_vm.applicationSelected)}},model:{value:(_vm.applicationSelected),callback:function ($$v) {_vm.applicationSelected=$$v},expression:"applicationSelected"}})],1),_c('v-spacer'),_c('v-col',{attrs:{"cols":"2"}},[_c('div',{staticClass:"d-flex"},[_c('v-autocomplete',{staticClass:"ml-4",attrs:{"items":_vm.countries,"filter":_vm.filterCountries,"placeholder":"Geo","clearable":"","filled":_vm.selectedCountry !== null},on:{"change":function($event){return _vm.updateWithFilters()}},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}})],1)])],1),_c('v-data-table',{staticClass:"mailbox-table elevation-0",attrs:{"headers":_vm.headers,"sort-by":'date_raw',"sort-desc":true,"items":_vm.mailbox,"items-per-page":50,"loading":_vm.showLoading === true,"loading-text":"Loading... Please wait"},on:{"click:row":_vm.openMail},scopedSlots:_vm._u([{key:"item.date_raw",fn:function(ref){
var item = ref.item;
return [(!item.is_seen)?_c('strong',[_vm._v(_vm._s(item.date))]):_c('span',[_vm._v(_vm._s(item.date))])]}},{key:"item.geo",fn:function(ref){
var item = ref.item;
return [(!item.is_seen)?_c('strong',[_vm._v(_vm._s(item.geo))]):_c('span',[_vm._v(_vm._s(item.geo))])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [(!item.is_seen)?_c('strong',[_vm._v(_vm._s(item.email))]):_c('span',[_vm._v(_vm._s(item.email))])]}},{key:"item.subject",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap","overflow":"hidden","text-overflow":"ellipsis"}},[(!item.is_seen)?_c('strong',[_vm._v(_vm._s(_vm.getSubject(item)))]):_c('span',[_vm._v(_vm._s(_vm.getSubject(item)))]),_c('em',{staticClass:"ml-2",staticStyle:{"color":"gray"}},[_vm._v(_vm._s(_vm.getBodyPreview(item)))])])]}},{key:"item.has_attachments",fn:function(ref){
var item = ref.item;
return [(item.has_attachments === true)?_c('v-icon',[_vm._v(" mdi-paperclip ")]):_vm._e()]}}])})],1)],1)],1),_c('MailboxMailDialog',{attrs:{"applicationId":_vm.applicationSelected,"show":_vm.mailDialog,"with":_vm.mailSelected.email,"messageId":_vm.mailSelected.message_id},on:{"onClose":function($event){_vm.mailDialog = false}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }